.home {
  height: 100%;
  background-size:cover;
@media only screen and (min-width: $medium-breakpoint) {
  .sombra {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    position: fixed;
    background-color: $black;

  }
  .marge {
    margin-left: 25%;
  }
}
.degradat {
  background: #7BC3E9;
  background: -webkit-linear-gradient(#7BC3E9, #38A8DA);
  background: -o-linear-gradient(#7BC3E9, #38A8DA);
  background: -moz-linear-gradient(#7BC3E9, #38A8DA);
  background: linear-gradient(#7BC3E9, #38A8DA);
  height: 160vh;
  img {
    display: block;
    margin: 0 auto;
    padding-top: rem-calc(60);
  }
  .pantalles {
    margin-bottom: rem-calc(80);
  }
  h1 {
  font-size: rem-calc(65);
  color: $white;
  font-family: 'Barlow'; //'sisco-light';
      font-weight: 300;
  padding-top: rem-calc(70);
  width: rem-calc(300);
  margin: 0 auto;
}
}
@media only screen and (min-width: $medium-breakpoint) {
  .degradat {
  height: 100%;
}
}
.port-urv {
  background-color: #f0f0f0;
  height: 100vh;
  img {
    display: block;
    margin: 0 auto;
    padding-top: rem-calc(60);
  }
  .pantalles {
    margin-bottom: rem-calc(80);
  }
  h1 {
  font-size: rem-calc(40);
  color: #000;
  font-family: 'Barlow'; //'sisco-light';
      font-weight: 300;
  padding-top: rem-calc(70);
  //width: rem-calc(600);
  margin: 0 auto;
}
  @media only screen and (min-width: $medium-breakpoint) {
    h1 {
      font-size: rem-calc(70);
      width: rem-calc(600);
}
  }
}

.general {
  h1 {
    font-size: rem-calc(65);
    color: $white;
    font-family: 'Barlow'; //'sisco-light';
      font-weight: 300;
    padding-top: rem-calc(150);
    padding-bottom: rem-calc(50);
    width: rem-calc(800);
    margin: 0 auto;
    a {
      text-decoration: none;
      color: $white;
      padding-top: rem-calc(100);
      width: 100%;
      &:hover {
        text-decoration: underline;
       }
    }
  }
  .pict {
    padding-bottom: rem-calc(160);
  }
}


  @media #{$medium-up} {

  }

  time {
    position: relative;
    padding-left: 10px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background: $secondary-color;
      width: 5px;
      height: 11px;
      left: 0;
      top: 50%;
      margin-top: -5.5px;
    }

    @media #{$medium-up} {
      font-size: rem-calc(15);
    }
  }

  article {
    margin-bottom: 2rem;

    h3 {
      border: 0;
      padding: 0;
      font-size: rem-calc(23);

      @media #{$medium-up} {
        font-size: rem-calc(25);
      }

      @media #{$large-up} {
        font-size: rem-calc(24);
      }
    }

    img {
      margin-bottom: rem-calc(15);
    }
  }

  .read-more {
    background: $primary-color;
  }

  @media #{$small-up} {
    .news-main {
      @include grid-row($behavior: nest);
    }

    .news-img {
      @include grid-column($columns: 4, $push: 8);
    }

    .news-txt {
      @include grid-column($columns: 8, $pull: 4);
    }
  }

  @media #{$large-up} {
    .news-container {
      @include grid-row($behavior: nest);
    }

    article {
       @include grid-column($columns: 6);
    }
  }
}
