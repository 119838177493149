.main-footer {
  background: $secondary-color;
  margin-top: 3rem;
  padding: rem-calc(27) 0;

  p,
  ul {
    font-size: rem-calc(14);

    @media #{$small-up} {
      font-size: rem-calc(15);
    }

    @media #{$medium-up} {
      font-size: rem-calc(16);
    }

    @media #{$large-up} {
      font-size: rem-calc(17);
    }
  }

  .footer-foto {
    @include grid-column($columns: 4)
  }

  .footer-text {
    @include grid-column($columns: 8)
  }
}
