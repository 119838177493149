.columns {
  padding-left: 0;
  padding-right: 0;
}
.pagina {
@media only screen and (min-width: $medium-breakpoint) {
  .sombra {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    background-color: $black;
    position: fixed;
    overflow-y: auto;

  }
  .marge {
    margin-left: 25%;
  }
}
.sombra {
    background-color: $black;
  }
h1 {
  font-size: rem-calc(40);
  color: #605F60;
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  padding-top: rem-calc(38);
  background-color: #F1F1F1;
  padding-left: rem-calc(40);
  padding-bottom: rem-calc(15);
  margin: 0;
  margin-bottom: rem-calc(20);
}
h2 {
  font-size: rem-calc(26);
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  color: #605F60;
  border-bottom: 2px solid $secondary-color;
  margin-bottom: rem-calc(15);
  margin-top: rem-calc(20);
}
h3 {
  font-size: rem-calc(21);
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  color: $secondary-color;
  margin-top: rem-calc(15);
}
h4 {
  font-size: rem-calc(18);
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  color: #605F60;
  margin-bottom: rem-calc(15);
}

h5 {
  font-size: rem-calc(16);
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  color: #605F60;
  margin-bottom: 0;
}
main {
  padding-left: rem-calc(40);
  padding-right: rem-calc(40);
  max-width: 62.5rem;
}

p {
  font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
  font-size: rem-calc(16);
  color: #605F60;
  margin-bottom: rem-calc(15);
}
a {
   font-size: rem-calc(16);
   font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
   color: #6DC3EE;
   text-decoration: none;
   &:hover {
    text-decoration: underline;
   }
}

span {
  font-size: rem-calc(17);
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  color: $secondary-color;
}
span.fa {
  font-family: 'FontAwesome';
  color: #605F60;
}
.accordion {
  h4 {
    font-size: rem-calc(18);
    font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
    color: #605F60;
    margin-bottom: rem-calc(15);
    border-bottom: 1px solid #9F9E9E;
  }
  p {
    margin-bottom: rem-calc(15);
  }
  .info {
    h4 {
      border-bottom: none;
    }
  }
  .desplegar {
    float: right;
    margin-top: rem-calc(-5);
  }
  .active .desplegar {
        @extend .plegar;
  }
}
.main-content  {
  ul:not(.accordion) {
  font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
  font-size: rem-calc(16);
  color: #605F60;
  list-style: none;
  margin-top: rem-calc(15);
    li {
      padding-left: 1.3em!important;
    }
    li:before {
      background-image: url('img/flet.png')!important;
      background-repeat: no-repeat;
      background-color: #fff!important;
      height: 13px!important;
      top: .28em!important;
    }
}
  .duesCol {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
 p img {
  margin-top: rem-calc(15);
  margin-bottom: rem-calc(15);
 }
 p .imgp {
  margin-top: 0;
  margin-bottom: 0;
 }
 p span {
  display: inline-block;
  margin-top: rem-calc(15);
 }
 p span.fa {
  margin-top: 0;
 }
}
.accordion {
  margin-left: 0!important;
  li {
    padding-left: 0!important;
    &:before {
      background: none!important;
    }
    a {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
}

.main-content ol {
  font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
  font-size: rem-calc(16);
  color: #605F60;
  margin-top: rem-calc(15);
  margin-left: rem-calc(20);;
}
.alert {
  background-color: #F1F1F1;
  padding: rem-calc(15);
  margin-bottom: rem-calc(20);
  span {
    display: inline-block;
    color: #EC7F29;
    margin-right: rem-calc(10);
  }
  h4 {
    font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
    font-size: rem-calc(20);
    color: #EC7F29;
  }
}
.info {
  background-color: #F1F1F1;
  padding: rem-calc(15);
  margin-bottom: rem-calc(20);
  span {
    display: inline-block;
    margin-right: rem-calc(10);
  }
  h4 {
    font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
    font-size: rem-calc(20);
    color: #1E67AB;
  }
}

}
.versio {
  font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
  font-size: rem-calc(14);
  color: $white;
  margin-bottom: rem-calc(15);
}
.obert .versio {
  display:none;
}
.opac {
  position: relative;
  a {
    &:hover {
      //opacity: .7;
      &:before {
        content: '';
        width:100%;
        height:100%;
        position:absolute;
        background-color:#000;
        opacity:0.5;
        left: 0;
      }
      &:after {
        content: '';
        background-image: url('img/ico-plus.svg');
        background-repeat: no-repeat;
        width: 60px;
        height: 60px;
        position:absolute;
        top: (50%);
        right: (50%);
        margin-top: -30px;
        margin-right: -30px;
      }
    }
    img {
      margin: 0 !important;
    }
  }
}
