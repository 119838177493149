.main-header {
  h1 {
    margin: rem-calc(22 0 18);

    @media #{$medium-up} {
      margin: rem-calc(19 0 21);
    }

    a:hover {
      border: 0;
    }

    img {
      width: 237px;

      @media #{$small-up} {
        width: 293px;
      }

      @media #{$medium-up} {
        width: 360px;
      }

      @media #{$large-up} {
        width: 402px;
      }
    }
  }

  .lang,
  .tools {
    display: none;
  }

  @media #{$medium-up} {
    h1 {
      width: 40%;
      float: left;
    }

    .tools {
      display: block;
      width: 60%;
      float: right;
      text-align: right;
      padding-top: 38px;
    }

    .lang {
      display: inline-block;
      margin-bottom: 0;

      li {
        display: inline;

        &.active a {
          background: $secondary-color;

          &:hover {
            text-decoration: none;
            cursor: default;

            abbr {
              cursor: default;
            }
          }
        }
      }

      a {
        display: inline-block;
        padding: 0 0.5em;
        line-height: 32px;

        &:hover {
          border: 0;
          text-decoration: underline;

          abbr {
            cursor: pointer;
          }
        }
      }
    }

    .share {
      display: inline-block;
      margin: 0 0 0 6px;
      vertical-align: top;
      list-style: none;

      li {
        float: left;
      }

      a {
        margin: 0 0 0 12px;
        display: inline-block;
      }
    }
  }
}

.menu-trigger {
  //position: absolute;
  top: 29px;
  right: $column-gutter/2;
  z-index: 20;
  text-transform: uppercase;
  text-align: center;
  font-size: rem-calc(12);

  &:before {
    //@extend .icon-menu-small;
    content: '';
    display: block;
    margin-bottom: 3px;
  }

  @media #{$medium-up} {
    //display: none;
  }
}
