.related-links {
  background: $light-gray;
  margin-bottom: 1.5rem;

  h2 {
    position: relative;
    background: $secondary-color;
    font-size: rem-calc(17);
    font-weight: 600;
    padding: 0.75rem 0 0.75rem 50px;
    margin: 0;
    position: relative;
    border: 0;
    // @include semibold;

    &:before {
      @extend .icon-enllacos;
      position: absolute;
      content: '';
      left: 20px;
      top: 50%;
      margin-top: -$icon-enllacos-height/2;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    font-size: rem-calc(15);

    li {
      &:last-child {
        a {
          border: 0;
        }
      }
    }

    a {
      display: block;
      position: relative;
      border-bottom: 1px solid $border;
      padding: 0.75rem 1rem 0.75rem 50px;

      &:hover {
        border-bottom: 1px solid $border;
        text-decoration: underline;
      }

      &:before {
        position: absolute;
        content: '';
        left: 14px;
        top: 50%;
      }

      &.pdf {
        &:before {
          @extend .icon-enllacos-pdf;
          margin-top: -$icon-enllacos-pdf-height/2
        }
      }
      &.link {
        &:before {
          // @extend .icon-enllacos-link;
          // margin-top: -$icon-enllacos-link-height/2
        }
      }
      &.doc {
        &:before {
          // @extend .icon-enllacos-doc;
          // margin-top: -$icon-enllacos-doc-height/2
        }
      }
    }
  }
}
