.main-content {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

// imatge esquerra/dreta
.img-left,
.img-right {
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
}
@media #{$medium-up} {
  .img-left {
    img {
      float: left;
      margin-right: $column-gutter/2;
    }
  }

  .img-right {
    img {
      float: right;
      margin-left: $column-gutter/2;
    }
  }
}

// llistes
.main-content {
  ul:not(.pagination,.related-links-list) {
    list-style: none;
    margin-left: $column-gutter/2;

    li {
      position: relative;
      padding-left: 1em;
      margin-bottom: 0.25em;

      &:before {
        content: '';
        background: $primary-color;
        display: block;
        width: 9px;
        height: 9px;
        position: absolute;
        left: 0;
        top: 0.55em;
      }

      p {
        margin-bottom: 0;
      }

      // subllista 1
      ul {
        margin-left: 0;

        li {
          &:before {
            width: 8px;
            height: 8px;
            border-radius: $global-rounded;
            top: 0.6em;
          }

          p {
            font-size: inherit;
          }

          // subllista 2
          ul {
            margin-bottom: 0;
            margin-top: 0.5em;
            font-size: rem-calc(14);

            li {
              &:before {
                //width: 7px;
                //height: 7px;
                //border-radius: $global-rounded;
                //top: 0.6em;
                //border: 2px solid $primary-color;
                background: none;
              }

              p {
                font-size: inherit;
              }
            }
          }
        }
      }
    }

    // llistat dues columnes
    &.duesCol {
      @media #{$medium-up} {
        @include block-grid(2);
        margin: 0 0 $paragraph-margin-bottom 0;

        li {
          padding-left: 1em;
          padding-bottom: 0;
        }
      }
    }

    // llista amb fons de color
    &.llista_fons {
      margin-left: 0;

      li {
        background-color: $secondary-color;
        padding: 0.5em 30px;
        margin-bottom: 0.5em;

        &:before {
          top: 1.05em;
          left: 14px;
        }
      }
    }

    // llista separadors
    &.llista_separadors {
      margin-left: 0;

      li {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px dotted $secondary-color;
        margin-bottom: 0;

        &:before {
          top: 1.05em;
        }
      }
    }

    // llista caixa
    &.llista_caixa {
      margin-left: 0;
      background: $secondary-color;
      padding: 8px;

      li {
        padding: 0.5em 30px;
        margin-bottom: 0.55em;
        background-color: $white;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          top: 1.05em;
          left: 14px;
        }

        &.caixaTitol {
          color: $white;
          background: $primary-color;
          color: $white;
          font-size: rem-calc(17);
        }
      }
    }
  }

  ol {
    margin-left: $column-gutter;
  }
}

// botons
.btn,
.button {
  @include button($padding: $button-sml);

  a {
    color: $white;
    text-decoration: none;
  }
}

a.button {
  text-decoration: none;
}

// baixar arxiu
.dl {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    @extend .icon-download;
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

// vincle destacat
.dest {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background: $primary-color;
    border-radius: 3px;
  }
}
