// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$alerta-name: 'alerta';
$alerta-x: 0px;
$alerta-y: 0px;
$alerta-offset-x: 0px;
$alerta-offset-y: 0px;
$alerta-width: 43px;
$alerta-height: 44px;
$alerta-total-width: 148px;
$alerta-total-height: 138px;
$alerta-image: 'img/spritesheet.png?1692686065846';
$alerta: (0px, 0px, 0px, 0px, 43px, 44px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'alerta', );
$close-negre-name: 'close-negre';
$close-negre-x: 0px;
$close-negre-y: 84px;
$close-negre-offset-x: 0px;
$close-negre-offset-y: -84px;
$close-negre-width: 31px;
$close-negre-height: 31px;
$close-negre-total-width: 148px;
$close-negre-total-height: 138px;
$close-negre-image: 'img/spritesheet.png?1692686065846';
$close-negre: (0px, 84px, 0px, -84px, 31px, 31px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'close-negre', );
$close-name: 'close';
$close-x: 31px;
$close-y: 84px;
$close-offset-x: -31px;
$close-offset-y: -84px;
$close-width: 31px;
$close-height: 31px;
$close-total-width: 148px;
$close-total-height: 138px;
$close-image: 'img/spritesheet.png?1692686065846';
$close: (31px, 84px, -31px, -84px, 31px, 31px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'close', );
$desplegar-name: 'desplegar';
$desplegar-x: 62px;
$desplegar-y: 84px;
$desplegar-offset-x: -62px;
$desplegar-offset-y: -84px;
$desplegar-width: 27px;
$desplegar-height: 27px;
$desplegar-total-width: 148px;
$desplegar-total-height: 138px;
$desplegar-image: 'img/spritesheet.png?1692686065846';
$desplegar: (62px, 84px, -62px, -84px, 27px, 27px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'desplegar', );
$flet-name: 'flet';
$flet-x: 40px;
$flet-y: 68px;
$flet-offset-x: -40px;
$flet-offset-y: -68px;
$flet-width: 9px;
$flet-height: 13px;
$flet-total-width: 148px;
$flet-total-height: 138px;
$flet-image: 'img/spritesheet.png?1692686065846';
$flet: (40px, 68px, -40px, -68px, 9px, 13px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'flet', );
$ico-menu-name: 'ico-menu';
$ico-menu-x: 118px;
$ico-menu-y: 44px;
$ico-menu-offset-x: -118px;
$ico-menu-offset-y: -44px;
$ico-menu-width: 27px;
$ico-menu-height: 23px;
$ico-menu-total-width: 148px;
$ico-menu-total-height: 138px;
$ico-menu-image: 'img/spritesheet.png?1692686065846';
$ico-menu: (118px, 44px, -118px, -44px, 27px, 23px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'ico-menu', );
$ico-tancar-menu-name: 'ico-tancar-menu';
$ico-tancar-menu-x: 86px;
$ico-tancar-menu-y: 64px;
$ico-tancar-menu-offset-x: -86px;
$ico-tancar-menu-offset-y: -64px;
$ico-tancar-menu-width: 20px;
$ico-tancar-menu-height: 20px;
$ico-tancar-menu-total-width: 148px;
$ico-tancar-menu-total-height: 138px;
$ico-tancar-menu-image: 'img/spritesheet.png?1692686065846';
$ico-tancar-menu: (86px, 64px, -86px, -64px, 20px, 20px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'ico-tancar-menu', );
$icon-download-name: 'icon-download';
$icon-download-x: 118px;
$icon-download-y: 67px;
$icon-download-offset-x: -118px;
$icon-download-offset-y: -67px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 148px;
$icon-download-total-height: 138px;
$icon-download-image: 'img/spritesheet.png?1692686065846';
$icon-download: (118px, 67px, -118px, -67px, 30px, 20px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'icon-download', );
$icon-enllacos-pdf-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-x: 0px;
$icon-enllacos-pdf-y: 115px;
$icon-enllacos-pdf-offset-x: 0px;
$icon-enllacos-pdf-offset-y: -115px;
$icon-enllacos-pdf-width: 22px;
$icon-enllacos-pdf-height: 23px;
$icon-enllacos-pdf-total-width: 148px;
$icon-enllacos-pdf-total-height: 138px;
$icon-enllacos-pdf-image: 'img/spritesheet.png?1692686065846';
$icon-enllacos-pdf: (0px, 115px, 0px, -115px, 22px, 23px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'icon-enllacos-pdf', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 106px;
$icon-enllacos-y: 64px;
$icon-enllacos-offset-x: -106px;
$icon-enllacos-offset-y: -64px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 148px;
$icon-enllacos-total-height: 138px;
$icon-enllacos-image: 'img/spritesheet.png?1692686065846';
$icon-enllacos: (106px, 64px, -106px, -64px, 11px, 18px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'icon-enllacos', );
$icon-link-name: 'icon-link';
$icon-link-x: 118px;
$icon-link-y: 87px;
$icon-link-offset-x: -118px;
$icon-link-offset-y: -87px;
$icon-link-width: 22px;
$icon-link-height: 23px;
$icon-link-total-width: 148px;
$icon-link-total-height: 138px;
$icon-link-image: 'img/spritesheet.png?1692686065846';
$icon-link: (118px, 87px, -118px, -87px, 22px, 23px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'icon-link', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 0px;
$icon-menu-small-y: 44px;
$icon-menu-small-offset-x: 0px;
$icon-menu-small-offset-y: -44px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 148px;
$icon-menu-small-total-height: 138px;
$icon-menu-small-image: 'img/spritesheet.png?1692686065846';
$icon-menu-small: (0px, 44px, 0px, -44px, 40px, 40px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'icon-menu-small', );
$infor-name: 'infor';
$infor-x: 43px;
$infor-y: 0px;
$infor-offset-x: -43px;
$infor-offset-y: 0px;
$infor-width: 43px;
$infor-height: 44px;
$infor-total-width: 148px;
$infor-total-height: 138px;
$infor-image: 'img/spritesheet.png?1692686065846';
$infor: (43px, 0px, -43px, 0px, 43px, 44px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'infor', );
$logo-linkedin-name: 'logo-linkedin';
$logo-linkedin-x: 86px;
$logo-linkedin-y: 32px;
$logo-linkedin-offset-x: -86px;
$logo-linkedin-offset-y: -32px;
$logo-linkedin-width: 32px;
$logo-linkedin-height: 32px;
$logo-linkedin-total-width: 148px;
$logo-linkedin-total-height: 138px;
$logo-linkedin-image: 'img/spritesheet.png?1692686065846';
$logo-linkedin: (86px, 32px, -86px, -32px, 32px, 32px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'logo-linkedin', );
$logo-twitter-name: 'logo-twitter';
$logo-twitter-x: 86px;
$logo-twitter-y: 0px;
$logo-twitter-offset-x: -86px;
$logo-twitter-offset-y: 0px;
$logo-twitter-width: 32px;
$logo-twitter-height: 32px;
$logo-twitter-total-width: 148px;
$logo-twitter-total-height: 138px;
$logo-twitter-image: 'img/spritesheet.png?1692686065846';
$logo-twitter: (86px, 0px, -86px, 0px, 32px, 32px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'logo-twitter', );
$menu-blau-name: 'menu-blau';
$menu-blau-x: 118px;
$menu-blau-y: 0px;
$menu-blau-offset-x: -118px;
$menu-blau-offset-y: 0px;
$menu-blau-width: 30px;
$menu-blau-height: 22px;
$menu-blau-total-width: 148px;
$menu-blau-total-height: 138px;
$menu-blau-image: 'img/spritesheet.png?1692686065846';
$menu-blau: (118px, 0px, -118px, 0px, 30px, 22px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'menu-blau', );
$menu-negre-name: 'menu-negre';
$menu-negre-x: 118px;
$menu-negre-y: 22px;
$menu-negre-offset-x: -118px;
$menu-negre-offset-y: -22px;
$menu-negre-width: 30px;
$menu-negre-height: 22px;
$menu-negre-total-width: 148px;
$menu-negre-total-height: 138px;
$menu-negre-image: 'img/spritesheet.png?1692686065846';
$menu-negre: (118px, 22px, -118px, -22px, 30px, 22px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'menu-negre', );
$plegar-name: 'plegar';
$plegar-x: 89px;
$plegar-y: 84px;
$plegar-offset-x: -89px;
$plegar-offset-y: -84px;
$plegar-width: 27px;
$plegar-height: 27px;
$plegar-total-width: 148px;
$plegar-total-height: 138px;
$plegar-image: 'img/spritesheet.png?1692686065846';
$plegar: (89px, 84px, -89px, -84px, 27px, 27px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'plegar', );
$top-name: 'top';
$top-x: 40px;
$top-y: 44px;
$top-offset-x: -40px;
$top-offset-y: -44px;
$top-width: 43px;
$top-height: 24px;
$top-total-width: 148px;
$top-total-height: 138px;
$top-image: 'img/spritesheet.png?1692686065846';
$top: (40px, 44px, -40px, -44px, 43px, 24px, 148px, 138px, 'img/spritesheet.png?1692686065846', 'top', );
$alerta-2x-name: 'alerta@2x';
$alerta-2x-x: 0px;
$alerta-2x-y: 0px;
$alerta-2x-offset-x: 0px;
$alerta-2x-offset-y: 0px;
$alerta-2x-width: 86px;
$alerta-2x-height: 88px;
$alerta-2x-total-width: 296px;
$alerta-2x-total-height: 276px;
$alerta-2x-image: 'img/spritesheet@2x.png?1692686065846';
$alerta-2x: (0px, 0px, 0px, 0px, 86px, 88px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'alerta@2x', );
$close-negre-2x-name: 'close-negre@2x';
$close-negre-2x-x: 0px;
$close-negre-2x-y: 168px;
$close-negre-2x-offset-x: 0px;
$close-negre-2x-offset-y: -168px;
$close-negre-2x-width: 62px;
$close-negre-2x-height: 62px;
$close-negre-2x-total-width: 296px;
$close-negre-2x-total-height: 276px;
$close-negre-2x-image: 'img/spritesheet@2x.png?1692686065846';
$close-negre-2x: (0px, 168px, 0px, -168px, 62px, 62px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'close-negre@2x', );
$close-2x-name: 'close@2x';
$close-2x-x: 62px;
$close-2x-y: 168px;
$close-2x-offset-x: -62px;
$close-2x-offset-y: -168px;
$close-2x-width: 62px;
$close-2x-height: 62px;
$close-2x-total-width: 296px;
$close-2x-total-height: 276px;
$close-2x-image: 'img/spritesheet@2x.png?1692686065846';
$close-2x: (62px, 168px, -62px, -168px, 62px, 62px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'close@2x', );
$desplegar-2x-name: 'desplegar@2x';
$desplegar-2x-x: 124px;
$desplegar-2x-y: 168px;
$desplegar-2x-offset-x: -124px;
$desplegar-2x-offset-y: -168px;
$desplegar-2x-width: 54px;
$desplegar-2x-height: 54px;
$desplegar-2x-total-width: 296px;
$desplegar-2x-total-height: 276px;
$desplegar-2x-image: 'img/spritesheet@2x.png?1692686065846';
$desplegar-2x: (124px, 168px, -124px, -168px, 54px, 54px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'desplegar@2x', );
$flet-2x-name: 'flet@2x';
$flet-2x-x: 80px;
$flet-2x-y: 136px;
$flet-2x-offset-x: -80px;
$flet-2x-offset-y: -136px;
$flet-2x-width: 18px;
$flet-2x-height: 26px;
$flet-2x-total-width: 296px;
$flet-2x-total-height: 276px;
$flet-2x-image: 'img/spritesheet@2x.png?1692686065846';
$flet-2x: (80px, 136px, -80px, -136px, 18px, 26px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'flet@2x', );
$ico-menu-2x-name: 'ico-menu@2x';
$ico-menu-2x-x: 236px;
$ico-menu-2x-y: 88px;
$ico-menu-2x-offset-x: -236px;
$ico-menu-2x-offset-y: -88px;
$ico-menu-2x-width: 54px;
$ico-menu-2x-height: 46px;
$ico-menu-2x-total-width: 296px;
$ico-menu-2x-total-height: 276px;
$ico-menu-2x-image: 'img/spritesheet@2x.png?1692686065846';
$ico-menu-2x: (236px, 88px, -236px, -88px, 54px, 46px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'ico-menu@2x', );
$ico-tancar-menu-2x-name: 'ico-tancar-menu@2x';
$ico-tancar-menu-2x-x: 172px;
$ico-tancar-menu-2x-y: 128px;
$ico-tancar-menu-2x-offset-x: -172px;
$ico-tancar-menu-2x-offset-y: -128px;
$ico-tancar-menu-2x-width: 40px;
$ico-tancar-menu-2x-height: 40px;
$ico-tancar-menu-2x-total-width: 296px;
$ico-tancar-menu-2x-total-height: 276px;
$ico-tancar-menu-2x-image: 'img/spritesheet@2x.png?1692686065846';
$ico-tancar-menu-2x: (172px, 128px, -172px, -128px, 40px, 40px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'ico-tancar-menu@2x', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 236px;
$icon-download-2x-y: 134px;
$icon-download-2x-offset-x: -236px;
$icon-download-2x-offset-y: -134px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 296px;
$icon-download-2x-total-height: 276px;
$icon-download-2x-image: 'img/spritesheet@2x.png?1692686065846';
$icon-download-2x: (236px, 134px, -236px, -134px, 60px, 40px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'icon-download@2x', );
$icon-enllacos-pdf-2x-name: 'icon-enllacos-pdf@2x';
$icon-enllacos-pdf-2x-x: 0px;
$icon-enllacos-pdf-2x-y: 230px;
$icon-enllacos-pdf-2x-offset-x: 0px;
$icon-enllacos-pdf-2x-offset-y: -230px;
$icon-enllacos-pdf-2x-width: 44px;
$icon-enllacos-pdf-2x-height: 46px;
$icon-enllacos-pdf-2x-total-width: 296px;
$icon-enllacos-pdf-2x-total-height: 276px;
$icon-enllacos-pdf-2x-image: 'img/spritesheet@2x.png?1692686065846';
$icon-enllacos-pdf-2x: (0px, 230px, 0px, -230px, 44px, 46px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'icon-enllacos-pdf@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 212px;
$icon-enllacos-2x-y: 128px;
$icon-enllacos-2x-offset-x: -212px;
$icon-enllacos-2x-offset-y: -128px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 296px;
$icon-enllacos-2x-total-height: 276px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png?1692686065846';
$icon-enllacos-2x: (212px, 128px, -212px, -128px, 22px, 36px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'icon-enllacos@2x', );
$icon-link-2x-name: 'icon-link@2x';
$icon-link-2x-x: 236px;
$icon-link-2x-y: 174px;
$icon-link-2x-offset-x: -236px;
$icon-link-2x-offset-y: -174px;
$icon-link-2x-width: 44px;
$icon-link-2x-height: 46px;
$icon-link-2x-total-width: 296px;
$icon-link-2x-total-height: 276px;
$icon-link-2x-image: 'img/spritesheet@2x.png?1692686065846';
$icon-link-2x: (236px, 174px, -236px, -174px, 44px, 46px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'icon-link@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 0px;
$icon-menu-small-2x-y: 88px;
$icon-menu-small-2x-offset-x: 0px;
$icon-menu-small-2x-offset-y: -88px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 296px;
$icon-menu-small-2x-total-height: 276px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png?1692686065846';
$icon-menu-small-2x: (0px, 88px, 0px, -88px, 80px, 80px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'icon-menu-small@2x', );
$infor-2x-name: 'infor@2x';
$infor-2x-x: 86px;
$infor-2x-y: 0px;
$infor-2x-offset-x: -86px;
$infor-2x-offset-y: 0px;
$infor-2x-width: 86px;
$infor-2x-height: 88px;
$infor-2x-total-width: 296px;
$infor-2x-total-height: 276px;
$infor-2x-image: 'img/spritesheet@2x.png?1692686065846';
$infor-2x: (86px, 0px, -86px, 0px, 86px, 88px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'infor@2x', );
$logo-linkedin-2x-name: 'logo-linkedin@2x';
$logo-linkedin-2x-x: 172px;
$logo-linkedin-2x-y: 64px;
$logo-linkedin-2x-offset-x: -172px;
$logo-linkedin-2x-offset-y: -64px;
$logo-linkedin-2x-width: 64px;
$logo-linkedin-2x-height: 64px;
$logo-linkedin-2x-total-width: 296px;
$logo-linkedin-2x-total-height: 276px;
$logo-linkedin-2x-image: 'img/spritesheet@2x.png?1692686065846';
$logo-linkedin-2x: (172px, 64px, -172px, -64px, 64px, 64px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'logo-linkedin@2x', );
$logo-twitter-2x-name: 'logo-twitter@2x';
$logo-twitter-2x-x: 172px;
$logo-twitter-2x-y: 0px;
$logo-twitter-2x-offset-x: -172px;
$logo-twitter-2x-offset-y: 0px;
$logo-twitter-2x-width: 64px;
$logo-twitter-2x-height: 64px;
$logo-twitter-2x-total-width: 296px;
$logo-twitter-2x-total-height: 276px;
$logo-twitter-2x-image: 'img/spritesheet@2x.png?1692686065846';
$logo-twitter-2x: (172px, 0px, -172px, 0px, 64px, 64px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'logo-twitter@2x', );
$menu-blau-2x-name: 'menu-blau@2x';
$menu-blau-2x-x: 236px;
$menu-blau-2x-y: 0px;
$menu-blau-2x-offset-x: -236px;
$menu-blau-2x-offset-y: 0px;
$menu-blau-2x-width: 60px;
$menu-blau-2x-height: 44px;
$menu-blau-2x-total-width: 296px;
$menu-blau-2x-total-height: 276px;
$menu-blau-2x-image: 'img/spritesheet@2x.png?1692686065846';
$menu-blau-2x: (236px, 0px, -236px, 0px, 60px, 44px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'menu-blau@2x', );
$menu-negre-2x-name: 'menu-negre@2x';
$menu-negre-2x-x: 236px;
$menu-negre-2x-y: 44px;
$menu-negre-2x-offset-x: -236px;
$menu-negre-2x-offset-y: -44px;
$menu-negre-2x-width: 60px;
$menu-negre-2x-height: 44px;
$menu-negre-2x-total-width: 296px;
$menu-negre-2x-total-height: 276px;
$menu-negre-2x-image: 'img/spritesheet@2x.png?1692686065846';
$menu-negre-2x: (236px, 44px, -236px, -44px, 60px, 44px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'menu-negre@2x', );
$plegar-2x-name: 'plegar@2x';
$plegar-2x-x: 178px;
$plegar-2x-y: 168px;
$plegar-2x-offset-x: -178px;
$plegar-2x-offset-y: -168px;
$plegar-2x-width: 54px;
$plegar-2x-height: 54px;
$plegar-2x-total-width: 296px;
$plegar-2x-total-height: 276px;
$plegar-2x-image: 'img/spritesheet@2x.png?1692686065846';
$plegar-2x: (178px, 168px, -178px, -168px, 54px, 54px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'plegar@2x', );
$top-2x-name: 'top@2x';
$top-2x-x: 80px;
$top-2x-y: 88px;
$top-2x-offset-x: -80px;
$top-2x-offset-y: -88px;
$top-2x-width: 86px;
$top-2x-height: 48px;
$top-2x-total-width: 296px;
$top-2x-total-height: 276px;
$top-2x-image: 'img/spritesheet@2x.png?1692686065846';
$top-2x: (80px, 88px, -80px, -88px, 86px, 48px, 296px, 276px, 'img/spritesheet@2x.png?1692686065846', 'top@2x', );
$spritesheet-width: 148px;
$spritesheet-height: 138px;
$spritesheet-image: 'img/spritesheet.png?1692686065846';
$spritesheet-sprites: ($alerta, $close-negre, $close, $desplegar, $flet, $ico-menu, $ico-tancar-menu, $icon-download, $icon-enllacos-pdf, $icon-enllacos, $icon-link, $icon-menu-small, $infor, $logo-linkedin, $logo-twitter, $menu-blau, $menu-negre, $plegar, $top, );
$spritesheet: (148px, 138px, 'img/spritesheet.png?1692686065846', $spritesheet-sprites, );
$retina-spritesheet-width: 296px;
$retina-spritesheet-height: 276px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1692686065846';
$retina-spritesheet-sprites: ($alerta-2x, $close-negre-2x, $close-2x, $desplegar-2x, $flet-2x, $ico-menu-2x, $ico-tancar-menu-2x, $icon-download-2x, $icon-enllacos-pdf-2x, $icon-enllacos-2x, $icon-link-2x, $icon-menu-small-2x, $infor-2x, $logo-linkedin-2x, $logo-twitter-2x, $menu-blau-2x, $menu-negre-2x, $plegar-2x, $top-2x, );
$retina-spritesheet: (296px, 276px, 'img/spritesheet@2x.png?1692686065846', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$alerta-group-name: 'alerta';
$alerta-group: ('alerta', $alerta, $alerta-2x, );
$close-negre-group-name: 'close-negre';
$close-negre-group: ('close-negre', $close-negre, $close-negre-2x, );
$close-group-name: 'close';
$close-group: ('close', $close, $close-2x, );
$desplegar-group-name: 'desplegar';
$desplegar-group: ('desplegar', $desplegar, $desplegar-2x, );
$flet-group-name: 'flet';
$flet-group: ('flet', $flet, $flet-2x, );
$ico-menu-group-name: 'ico-menu';
$ico-menu-group: ('ico-menu', $ico-menu, $ico-menu-2x, );
$ico-tancar-menu-group-name: 'ico-tancar-menu';
$ico-tancar-menu-group: ('ico-tancar-menu', $ico-tancar-menu, $ico-tancar-menu-2x, );
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllacos-pdf-group-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-group: ('icon-enllacos-pdf', $icon-enllacos-pdf, $icon-enllacos-pdf-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-link-group-name: 'icon-link';
$icon-link-group: ('icon-link', $icon-link, $icon-link-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$infor-group-name: 'infor';
$infor-group: ('infor', $infor, $infor-2x, );
$logo-linkedin-group-name: 'logo-linkedin';
$logo-linkedin-group: ('logo-linkedin', $logo-linkedin, $logo-linkedin-2x, );
$logo-twitter-group-name: 'logo-twitter';
$logo-twitter-group: ('logo-twitter', $logo-twitter, $logo-twitter-2x, );
$menu-blau-group-name: 'menu-blau';
$menu-blau-group: ('menu-blau', $menu-blau, $menu-blau-2x, );
$menu-negre-group-name: 'menu-negre';
$menu-negre-group: ('menu-negre', $menu-negre, $menu-negre-2x, );
$plegar-group-name: 'plegar';
$plegar-group: ('plegar', $plegar, $plegar-2x, );
$top-group-name: 'top';
$top-group: ('top', $top, $top-2x, );
$retina-groups: ($alerta-group, $close-negre-group, $close-group, $desplegar-group, $flet-group, $ico-menu-group, $ico-tancar-menu-group, $icon-download-group, $icon-enllacos-pdf-group, $icon-enllacos-group, $icon-link-group, $icon-menu-small-group, $infor-group, $logo-linkedin-group, $logo-twitter-group, $menu-blau-group, $menu-negre-group, $plegar-group, $top-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
