.home {
  @media only screen and (min-width: $medium-breakpoint) {
  .main-nav {
    margin-bottom: $paragraph-margin-bottom;
    background: $black;
    padding: rem-calc(27 23);
    }
  }
    .main-nav {

  // nav mobile
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
  padding-bottom: rem-calc(15);
  transition: all 0.3s ease-out;
  text-align: left;

  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(14);
    margin: 0;

    a {
      font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
      font-size: rem-calc(14);
      color: $white;
      display: block;
      padding: 0.5em 0;
       &:hover {
          text-decoration: underline;
        }
    }
  }
@media only screen and (max-width: $medium-breakpoint) {
  .js & {
    z-index: 1;
    width: 100%;
    .menu {
      display: none;
    }
    .obrirmenupetit {
      display: block;
    }

    &.open {
      display: block;
    }
  }
}
  // nav medium
  @media #{$medium-up} {

    @include clearfix;
    display: block !important;
    background: $black;
    margin-bottom: rem-calc(44);
    padding: 0;
    position: static !important;
    width: auto !important;
    margin-top: 0 !important;

    .menu-wrapper {
      @include grid-row;
    }

    ul {
        @include grid-column;
        position: relative;
        font-size: rem-calc(16);
        font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
        float: none;
        padding-left: rem-calc(15);

        li {
          position: relative;
          page-break-inside: avoid;
          padding-left: 35px;
          margin-bottom: 15px;
          > a {
            color: $white;
            padding: .4em;
            text-transform: uppercase;
            &:hover,
            &.is-hovered, &.active {
              text-decoration: none;
              color: #6DC3EE;
              &:after {
                border-top-color: $secondary-color !important;
              }
            }
          }
          &:before {
              content: '';
              width: 28px;
              height: 28px;
              display: block;
              background-repeat: no-repeat;
              position:absolute;
              left: 0;
          }
          &.acces {
              &:before {
                  background-image: url('/media/css/img/ico-acces.svg');
              }
          }
          &.continguts {
              &:before {
                  background-image: url('/media/css/img/ico-continguts.svg');
              }
          }
          &.usuaris {
              &:before {
                  background-image: url('/media/css/img/ico-usuaris.svg');
              }
          }
          &.carpetes {
              &:before {
                  background-image: url('/media/css/img/ico-carpetes.svg');
              }
          }
          &.menus {
              &:before {
                  background-image: url('/media/css/img/ico-menus.svg');
              }
          }
          &.media {
              &:before {
                  background-image: url('/media/css/img/ico-media.svg');
              }
          }
          &.formularis {
              &:before {
                  background-image: url('/media/css/img/ico-formularis.svg');
              }
          }
          &.utilitats {
              &:before {
                  background-image: url('/media/css/img/ico-utilitats.svg');
              }
          }
          &.diccionari {
              &:before {
                  background-image: url('/media/css/img/ico-diccionari.svg');
              }
          }
          .home &.inici {
            display: none;
          }

          &.active {
            > a {
              color: #6DC3EE;
            }
            .submenu {
              display: block;
              a {
                 font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
                 padding-top: 0!important;
                 padding-bottom: 0!important;
                 text-transform: none;
              }
            }
          }
        }
      }

      .submenu {
        padding: 0;
        background: $black;
        overflow: hidden;
        z-index: 10000;
        margin: 0;
        text-transform: none;
        display: none;
        text-align: left;
        margin-top: rem-calc(5);

        li {
          float: none;
          display: block;
          padding-left: 0;
          ul {
              margin-top: rem-calc(10);
              li {
                  a {
                      font-size: rem-calc(13);
                  }
              }
          }
        }

        a {
          //padding: 0.75em 1.5em;
          height: auto;
          text-transform: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
  }
  span {
    display:block;
    margin: 0 auto;
  }
  p {
    font-size: rem-calc(12);
    font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
    color: $white;
    margin-top: rem-calc(10);

  }
}
.logo {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(20);
}
.obert {
  width: 100%!important;
  transition: all 0.3s ease-out;
  z-index: 10!important;
  opacity: 0.95;
  @media only screen and (min-width: $medium-breakpoint) {
  .logo  {
    text-align: left;
    padding-left: rem-calc(20);
  }
}
}

@media only screen and (min-width: $medium-breakpoint) {
.open {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-rule: 1px solid #000;
  -moz-column-rule: 1px solid #000;
  column-rule: 1px solid #000;

  .botomenu {
    position: absolute;
    top: 4%;
    left: 90%;
  }
  ul {
    li {
      page-break-inside: avoid;
    }
  }
}
}
  ul {
    line-height: 1.5!important;
    font-size: rem-calc(14)!important;

  }
  .submenu {
    width: 100%!important;
    li {
      a {
        font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
        padding-top: 0!important;
        padding-bottom: 0!important;
      }
    }
  }

}
.pagina {
  @media only screen and (min-width: $medium-breakpoint) {
  .main-nav {
    margin-bottom: $paragraph-margin-bottom;
    background: $white;
    padding: rem-calc(27 23);
    }
  }
.main-nav {
  // nav mobile
  background: #000;
  transition: all 0.3s ease-out;
  text-align: left;
  padding-left: rem-calc(15);
  padding-right: rem-calc(15);
  padding-bottom: rem-calc(15);

  h2 {
    @include visually-hidden;
  }

  ul {
    list-style: none;
    font-size: rem-calc(14);
    margin: 0;

    a {
      color: $white;
      display: block;
      padding: 0.5em 0;
      font-size: rem-calc(14);
       &:hover {
          text-decoration: underline;
        }
    }
  }
@media only screen and (max-width: $medium-breakpoint) {
  .js & {
    z-index: 1;
    width: 100%;
    .menu {
      display: none;
    }
    .obrirmenupetit {
      display: block;
    }

    &.open {
      display: block;
    }
  }
}

  // nav medium
  @media #{$medium-up} {

    @include clearfix;
    display: block !important;
    margin-bottom: rem-calc(44);
    padding: 0;
    position: static !important;
    width: auto !important;
    margin-top: 0 !important;

    .menu-wrapper {
      @include grid-row;
    }

    ul {
      @include grid-column;
      position: relative;
      font-size: rem-calc(16);
      font-family: 'Barlow'; //'sisco-demibold';
      font-weight: 600;
      float: none;
      padding-left: rem-calc(15);

      li {
        position: relative;
        page-break-inside: avoid;
        padding-left: 35px;
        margin-bottom: 15px;
        > a {
          color: $white;
          padding: .4em;
          text-transform: uppercase;
          &:hover,
          &.is-hovered, &.active {
            text-decoration: none;
            color: #6DC3EE;
            &:after {
              border-top-color: $secondary-color !important;
            }
          }
        }
        &:before {
            content: '';
            width: 28px;
            height: 28px;
            display: block;
            background-repeat: no-repeat;
            position:absolute;
            left: 0;
        }
        &.acces {
            &:before {
                background-image: url('/media/css/img/ico-acces.svg');
            }
        }
        &.continguts {
            &:before {
                background-image: url('/media/css/img/ico-continguts.svg');
            }
        }
        &.usuaris {
            &:before {
                background-image: url('/media/css/img/ico-usuaris.svg');
            }
        }
        &.carpetes {
            &:before {
                background-image: url('/media/css/img/ico-carpetes.svg');
            }
        }
        &.menus {
            &:before {
                background-image: url('/media/css/img/ico-menus.svg');
            }
        }
        &.media {
            &:before {
                background-image: url('/media/css/img/ico-media.svg');
            }
        }
        &.formularis {
            &:before {
                background-image: url('/media/css/img/ico-formularis.svg');
            }
        }
        &.utilitats {
            &:before {
                background-image: url('/media/css/img/ico-utilitats.svg');
            }
        }
        &.diccionari {
            &:before {
                background-image: url('/media/css/img/ico-diccionari.svg');
            }
        }
        .home &.inici {
          display: none;
        }

        &.active {
          > a {
            color: #6DC3EE;
          }
          .submenu {
            display: block;
            a {
               font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
               padding-top: 0!important;
               padding-bottom: 0!important;
               text-transform: none;
            }
          }
        }
      }
    }

    .submenu {
      padding: 0;
      background: $black;
      overflow: hidden;
      z-index: 10000;
      margin: 0;
      text-transform: none;
      display: none;
      text-align: left;
      margin-top: rem-calc(5);

      li {
        float: none;
        display: block;
        padding-left: 0;
        ul {
            margin-top: rem-calc(10);
            li {
                a {
                    font-size: rem-calc(13);
                }
            }
        }
      }

      a {
        //padding: 0.75em 1.5em;
        height: auto;
        text-transform: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  span {
    display:block;
    margin: 0 auto;
  }
  p {
    font-size: rem-calc(12);
    font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
    color: $white;
    margin-top: rem-calc(10);

  }
}
.logo {
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(20);
}
.obert {
  width: 100%!important;
  transition: all 0.3s ease-out;
  z-index: 10!important;
  opacity: 0.98;
  .logo  {
    text-align: left;
    padding-left: rem-calc(20);
  }
}
@media only screen and (min-width: $medium-breakpoint) {
.open {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-rule: 1px solid #000;
  -moz-column-rule: 1px solid #000;
  column-rule: 1px solid #000;
   -moz-column-fill: balance;
       column-fill: balance;
    -webkit-column-fill: balance;
  .botomenu {
    position: absolute;
    top: 4%;
    left: 90%;
  }
  .search {
    display: none;
  }
}
}

  ul {
    line-height: 1.5!important;
  }
  .submenu {
    width: 100%!important;
    li {
      a {
        font-family: 'Barlow'; //'sisco-book';
      font-weight: 400;
        padding-top: 0!important;
        padding-bottom: 0!important;
      }
    }
  }

  input[type=search] {
    background-color: #000;
    height: 1.5rem;
    border: 1px solid #fff;
    box-shadow: none;
    margin-left: rem-calc(20);
    width: 100%;
    color: #fff;
  }
  button {
    height: 2.6rem;
    width: 35px;
    padding: 0;
    margin-left: rem-calc(3);
    background-color: #fff;
    border-color: #fff;
  }

}

.submenu li ul {
  display:none;
}
